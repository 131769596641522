#map-sidebar {
  z-index: 2000;
  left: -500px;
  transition-property: left;
  transition-duration: 1s;
  width: 500px;
}
@media only screen and (max-width: 500px) {
  #map-sidebar {
    width: 100%;
  }
}
#map-sidebar.open {
  left: 0;
}
#toggle-sidebar-button {
  position: absolute;
  left: 500px;
}
#map-sidebar.open #toggle-sidebar-button {
  position: relative;
  left: 0;
}
